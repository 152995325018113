<template>
    <div>
        <div v-if="success" >
            <p class="alert alert-success" v-if="success == true">
                Thank you for reaching out. We'll get back with you as soon as possible.
            </p>
        </div>
        <div v-else >
            <ul v-if="hasError" class="alert alert-danger pl-5" >
                <li
                    v-for="error in errors"
                    :key="error"
                    class="text-danger font-bold"
                >{{ error }}</li>
            </ul>
            <form>
                <div class="row mt-1">
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label class="sr-only" for="name-field">Name</label>
                            <input
                                type="text"
                                id="name-field"
                                class="w-100 px-3 border border-teal py-2"
                                placeholder="Name"
                                v-model="formData.name"
                                required
                            >
                        </div>

                        <div class="form-group mb-3">
                            <label class="sr-only" for="email-field">Email Address</label>
                            <input
                                type="email"
                                id="email-field"
                                class="w-100 px-3 border border-teal py-2"
                                placeholder="Email Address"
                                v-model="formData.email"
                                required
                            >
                        </div>

                        <div class="form-group mb-3">
                            <label class="sr-only" for="phone-number-field">Phone Number</label>
                            <input
                                type="tel"
                                id="phone-number-field"
                                class="w-100 px-3  border border-teal py-2"
                                placeholder="Phone Number"
                                v-model="formData.phone"
                                required
                            >
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-2">
                            <label class="sr-only" for="message-field">Message</label>
                            <textarea
                                id="message-field"
                                class="w-100 px-3 border border-teal py-2"
                                placeholder="Message"
                                v-model="formData.comments"
                                style="min-height: 158px; min-width: 201px;"
                            >
                            </textarea>
                        </div>
                    </div>
                    <div class="col-12">                    
                        <button
                            @click.prevent="submitForm"
                            type="submit"
                            :disabled="processing"
                            class="btn btn-orange text-white" 
                        >
                            Send Message &nbsp; <icon name="solid/angle-right" height="20" width="20" ></icon>
                        </button>
                        <div 
                            v-if="!processing"
                            class="p-3 text-muted font-italic d-inline-block" 
                            style="font-size:.9em;">
                            All&nbsp;fields&nbsp;required.</div>
                        <div
                            v-if="processing"
                            class="p-3 text-muted font-italic d-inline-block" 
                            style="font-size:.9em;" 
                            >Sending&nbsp;message.</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        nonce: {
            type: String,
            default: '',
        },
        userIp: {
            type: String,
            default: '',
        },
        userAgent: {
            type: String,
            default: '',
        },
        referrer: {
            type: String,
            default: '',
        }
    },

    data () {
        return {
            processing: false,
            errors: {},
            errorCode: null,
            success: false,
            hasError: false,
            formData: {
                name: '',
                email: '',
                phone: '',
                comments: '',
            },
            mailto: '',
            submitted: false,
        }
    },

    computed: {
        validate() {
            return this.formData.name != '' &&
                this.formData.email != '' &&
                this.formData.phone != '' &&
                this.formData.comments != ''
        },
    },

    mounted () {
        this.formData.user_ip = this.userIp
        this.formData.user_agent = this.userAgent
        this.formData.referrer = this.referrer
    },

    methods: {
        submitForm () {
            this.processing = true

            fetch('/wp-json/kerigansolutions/v1/submit-contact-form', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': this.nonce
                },
                body: JSON.stringify(this.formData)
            })
                .then(r => r.json())
                .then((res) => {
                    if(res.success == true){
                        this.success      = true
                        dataLayer.push({'event': 'contact_form_submitted'});
                    } else {
                        this.success      = false
                        this.hasError     = true
                        this.errors       = res.data
                    }

                    this.processing   = false
                })
            },
    }
}
</script>