window.Vue = require('vue')
Vue.config.devtools = true

// require Vue components that exist on all pages
require('./common.js')

// Vue Components that reside only on gerneric pages
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

Vue.component('gallery', require('./components/PhotoGallery.vue').default);
Vue.component('contact-form', require('./components/ContactForm.vue').default);
Vue.component('photo-gallery', require('./components/PhotoGalleryShortcode.vue').default);

// Define app
require('./app.js')