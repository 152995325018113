<template>
    <div>
        <div class="photo-gallery row no-gutters">
            <div 
                class="col-6 col-sm-4 col-md col-xl-4"
                v-for="(photo, index) in photos.slice(0,8)" 
                v-bind:key="photo.id"
                @click="openViewer(index)"
                 >
                <div class="photo-outer" v-if="index < 8">
                    <div 
                        class="photo embed-responsive embed-responsive-1by1 pointer lazy shadow" 
                        v-lazy:background="photo.sizes.thumbnail"
                    ></div> 
                 </div>
            </div>
            <div 
                class="col-6 col-sm-4 col-md col-xl-4 text-white d-flex align-items-center justify-content-center pointer p-4" 
                :class="'bg-'+color"
                @click="openViewer(0)"
                >view all {{ photos.length }} photos
            </div>
        </div>
        <portal to="photoviewer" v-if="galleryIsOpen" class="photo-viewer" >
            <div class="photo-viewer p-4">
                <div class="d-flex align-items-center justify-content-center flex-column flex-grow-1" style="height: 85vh; overflow:hidden; padding:1rem;" @click="closeViewer()" >
                    <img :src="activePhoto.url" :alt="activePhoto.name" style="max-width:100%;max-height:100%;" />
                    <p class="py-3" v-html="activePhoto.caption"></p>
                </div>
                <div class="p-2" style="height: 10vh;" >
                    <a class="my-4 text-blue" @click="prevPhoto(activePhoto.index)">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 306 306" style="transform: rotate(180deg); height:30px; enable-background:new 0 0 306 306;"  xml:space="preserve">
                            <polygon fill="#111" points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153"></polygon>
                        </svg>
                    </a>
                    
                    <a @click="closeViewer" class="btn btn-red text-white rounded-pill">close</a>

                    <a class="my-4 text-blue" @click="nextPhoto(activePhoto.index)">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 306 306" style="height:30px; enable-background:new 0 0 306 306;"  xml:space="preserve">
                            <polygon fill="#111" points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153"></polygon>
                        </svg>
                    </a>
                </div>
            </div> 
        </portal>
    </div>
</template>

<script>
    export default { 
        props: {
            color: {
                type: String,
                default: 'primary'
            },
            dataPhotos: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                photos: [],
                prev: null,
                next: null,
                galleryIsOpen: false,
                activePhoto: {},
                numPhotos: 0
            }
        },
        mounted () {
            this.photos = this.dataPhotos;
            this.numPhotos = this.photos.length;
            this.$emit('viewermounted')
        },
        methods: {
            openViewer(index){
                this.galleryIsOpen = true;
                this.activePhoto = this.photos[index];
                this.activePhoto.index = index;
                this.$root.photoGalleryOpen = true;
                this.$emit('vieweropened')
                //console.log(this.activePhoto);
            },
            closeViewer(){
                this.galleryIsOpen = false;
                this.$root.photoGalleryOpen = false;
                this.$emit('viewerclosed')
            },
            nextPhoto(index){
                let newNum = (index !== this.numPhotos-1 ? index+1 : 0);
                this.activePhoto = this.photos[newNum];
                this.activePhoto.index = newNum;
                this.$emit('viewernext')
            },
            prevPhoto(index){
                let newNum = (index !== 0 ? index-1 : this.numPhotos-1);
                this.activePhoto = this.photos[newNum];
                this.activePhoto.index = newNum;
                this.$emit('viewerprev')
            }
        }
    }
</script>

<style scoped>
.photo.embed-responsive {
    background-position: center !important;
    background-size: cover !important;
}

.photo-caption {
    padding: 1rem; 
    background-color: rgba(255,255,255,.8);
    color: #111;
    text-align: center;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .5s linear;
    margin: 0 !important;
}

.photo-viewer {
    position: absolute;
    top: 0;
    background: rgba(255,255,255,.9);
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photo-outer {
    border-width: .25rem !important;
}
.photo {

}

</style>